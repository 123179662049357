import React, { useState, useCallback, useEffect } from "react";
import { Stack, Title, Button, Text, Group, Container } from "@mantine/core";
import axios from "axios";
import HexInput from "./HexInput";
import "./HexBoard.css";

function HexBoard() {
  const [letters, setLetters] = useState(Array(19).fill(""));
  const [activeIndex, setActiveIndex] = useState(null);
  const [cooldown, setCooldown] = useState(false);
  const [result, setResult] = useState(null);
  const [flag, setFlag] = useState(null);
  const [userId, setUserId] = useState(null);
  const [topWord, setTopWord] = useState(null);


  useEffect(() => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const userIdIndex = pathSegments.indexOf("u") + 1;
    if (userIdIndex > 0 && userIdIndex < pathSegments.length) {
      setUserId(pathSegments[userIdIndex]);
    }
  }, []);

  const handleHexClick = useCallback((index) => {
    setActiveIndex(index);
  }, []);

  const handleKeyDown = useCallback((event, index) => {
    if (event.key.match(/^[a-zA-Z]$/)) {
      setLetters((prev) => {
        const newLetters = [...prev];
        newLetters[index] = event.key.toUpperCase();
        return newLetters;
      });
      setActiveIndex((prevIndex) => (prevIndex + 1) % 19);
    } else if (event.key === "Backspace") {
      setLetters((prev) => {
        const newLetters = [...prev];
        newLetters[index] = "";
        return newLetters;
      });
      setActiveIndex((prevIndex) => (prevIndex - 1 + 19) % 19);
    }
  }, []);

  const handleClear = useCallback(() => {
    setLetters(Array(19).fill(""));
    setActiveIndex(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!userId) {
      console.log("Missing userId");
      return;
    }
    if (cooldown) return;
    const allLetters = letters.join("");
    setCooldown(true);
    try {
      const response = await axios.post(
        "https://hexhunt.hackmit.org/solve_hex_hunt",
        { letters: allLetters, userId }
      );
      setResult(response.data.total);
      if (response.data.flag) {
        setFlag(response.data.flag);
      } else {
        setFlag(null);
      }
      setTopWord(response.data.top_word);
    } catch (error) {
      console.error("Error submitting letters:", error);
    } finally {
      setTimeout(() => {
        setCooldown(false);
      }, 3000);
    }
  }, [letters, cooldown, userId]);

  const handleClickOutside = useCallback((event) => {
    if (!event.target.closest(".hex-cell")) {
      setActiveIndex(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const isComplete = letters.every((letter) => letter !== "");

  const rowSizes = [3, 4, 5, 4, 3];

  return (
    <Container size="sm" className="hex-container">
      <Stack justify="space-between" className="hex-content">
        <Stack align="center" gap="xs">
          <Title order={2}>Hexhunt</Title>
          <Text size="sm" align="center">
            Explore Novel Arrangements By Linking Elements 1-by-1.
          </Text>
        </Stack>
        <div className="hex-grid">
        {rowSizes.map((size, rowIndex) => (
          <div key={rowIndex} className="hex-row">
            {Array(size)
              .fill()
              .map((_, colIndex) => {
                const index =
                  rowSizes.slice(0, rowIndex).reduce((a, b) => a + b, 0) +
                  colIndex;
                return (
                  <div key={colIndex} className="hex-cell">
                    <HexInput
                      value={letters[index]}
                      onClick={() => handleHexClick(index)}
                      isActive={activeIndex === index}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                    />
                  </div>
                );
              })}
          </div>
        ))}
      </div>
        <Stack spacing="xs">
          <div className="button-container">
            <Button onClick={handleClear} className="action-button clear-button">
              Clear
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!isComplete || cooldown}
              className="action-button submit-button"
            >
              Enter
            </Button>
          </div>
          <Group position="center" className="result-group" spacing="md">
            {topWord && (
              <Text size="sm" align="center">
                {topWord}
              </Text>
            )}
            {result !== null && (
              <Text size="sm" align="center">
                Hexhunt Score: {result}
              </Text>
            )}
            {flag && (
              <Text size="sm" align="center" color="green" weight={700}>
                {flag}
              </Text>
            )}
          </Group>
        </Stack>
      </Stack>
    </Container>
  );
}

export default HexBoard;
