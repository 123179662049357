import React from 'react';
import { MantineProvider } from '@mantine/core';
import HexBoard from './components/HexBoard';

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <div className="App" style={{ height: '100vh' }}>
        <HexBoard />
      </div>
    </MantineProvider>
  );
}

export default App;