import React, { useRef, useEffect } from 'react';
import './HexInput.css';

function HexInput({ value, onClick, isActive, onKeyDown }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isActive]);

  const handleInputClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div className={`hexagon-wrapper ${isActive ? 'active' : ''}`} onClick={onClick}>
      <div className="hexagon">
        {isActive ? (
          <input
            ref={inputRef}
            value={value}
            onChange={() => {}}
            onKeyDown={onKeyDown}
            onClick={handleInputClick}
            maxLength={1}
            className="hex-input"
          />
        ) : (
          <span className="hex-text">
            {value}
          </span>
        )}
      </div>
    </div>
  );
}

export default HexInput;